import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 40 27"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M0 4a4 4 0 0 1 4-4h32a4 4 0 0 1 4 4v18.875a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z" }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M20.515 11.847c-.017 1.35 1.203 2.103 2.121 2.55.945.46 1.262.754 1.258 1.165-.007.629-.753.906-1.451.917-1.218.019-1.926-.329-2.49-.592l-.438 2.053c.565.26 1.61.488 2.695.497 2.546 0 4.212-1.256 4.22-3.205.01-2.472-3.42-2.61-3.396-3.715.008-.335.328-.692 1.028-.783.347-.046 1.305-.081 2.39.418l.426-1.986a6.5 6.5 0 0 0-2.268-.416c-2.396 0-4.081 1.274-4.095 3.097m10.458-2.926c-.465 0-.857.271-1.032.687l-3.636 8.684h2.544l.506-1.4h3.109l.293 1.4H35l-1.956-9.37zm.355 2.532.735 3.518h-2.011zM17.43 8.92l-2.005 9.37h2.424l2.005-9.37zm-3.586 0-2.523 6.38-1.02-5.423c-.12-.605-.593-.955-1.118-.955H5.058L5 9.193c.847.184 1.809.48 2.392.797.356.194.458.364.575.824L9.9 18.292h2.563l3.927-9.37h-2.546Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      "fill-opacity": ".2",
      d: "M4 1h32v-2H4zm35 3v18.875h2V4zm-3 21.875H4v2h32zm-35-3V4h-2v18.875zm3 3a3 3 0 0 1-3-3h-2a5 5 0 0 0 5 5zm35-3a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5zM36 1a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5zM4-1a5 5 0 0 0-5 5h2a3 3 0 0 1 3-3z"
    }, null, -1)
  ])))
}
export default { render: render }